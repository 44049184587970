import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
const UserProfile = ({ toggle, data }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('userSuccData'));
    if (items) {
      setItems(items);
      // alert(JSON.stringify(items.user))
    }
  }, [])

  return (
    <div className='flex justify-center items-center'>
      <img
        className={"w-30 h-16 "}
        src={require("../Assets/logo.png")}
      />
    </div>
    // <div className={`flex gap-2 items-center 
    // ${toggle ? "bg-none trabsition-all duration-1000 delay-300" : "bg-white rounded-xl p-2 duration-1000 delay-300"}`}>
    //   <div className={"h-20 object-contain w-20 flex justify-center items-center"}>
    // <img
    //   className={"w-full h-full "}
    //   src={require("../Assets/logo.png")}
    // />
    //   </div>

    // </div>
  )
}

export default UserProfile
