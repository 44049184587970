import { BaseUrl } from "../Constants/BaseUrl";
import { LoginActionConst, LogOutActionConst, CurrentSideBaseStateActionConst } from "./AuthActionConst"
import { ToastContainer, toast } from 'react-toastify';



const ShowErrorError = (error) => {
    return toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

}
export const UserLoginAction = (email, password) => {
    return async dispatch => {
        dispatch({
            type: LoginActionConst.USER_LOGIN_REQUEST,

        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": email,
            "password": password
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}admin/api/signIn`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.token) {
                    dispatch({
                        type: LoginActionConst.USER_LOGIN_SUCC,
                        userSuccessData: result
                    })
                    localStorage.setItem('userSuccData', JSON.stringify(result));
                } else {
                    dispatch({
                        type: LoginActionConst.USER_LOGIN_FAIL,
                        userErrotData: result
                    })
                    ShowErrorError("Invalid email and password")
                }
            })
            .catch(error => console.log('error', error));
    };
};


export const UserLogoutAction = info => {
    return async dispatch => {
        dispatch({
            type: LogOutActionConst.LOGOUT_ACTION_CONST,

        })
    };
};


export const CurrentSideBaseStateAction = state => {
    return async dispatch => {
        dispatch({
            type: CurrentSideBaseStateActionConst.CURRENT_SIDE_BAR_STATUS,
            state: state

        })
    };
};
