
import * as BiIcon from "react-icons/bi"
import * as BsIcon from "react-icons/bs"
import * as TbIcon from "react-icons/tb"
import * as SiIcon from "react-icons/si"
import * as AiIcon from "react-icons/ai"
import * as MdIcon from "react-icons/md"




export const SideBarListData = [
    {
        id: 1,
        Name: "Home",
        Icon: <BiIcon.BiHomeAlt className={"h-6 w-6"} />,


    },
    {
        id: 2,
        Name: "All Students",
        Icon: <BsIcon.BsFillPersonLinesFill className="h-5 w-5" />,


    },
    {
        id: 10,
        Name: "Boards",
        Icon: <MdIcon.MdOutlineSchool className="h-5 w-5" />,
    },
    {
        id: 3,
        Name: "Past Papers",
        Icon: <AiIcon.AiOutlinePaperClip className="h-5 w-5" />,
    },

    {
        id: 7,
        Name: "Create My Paper",
        Icon: <BsIcon.BsCardList className="h-5 w-5" />,
    },

    {
        id: 5,
        Name: "Assistant Admin",
        Icon: <BsIcon.BsPersonGear className="h-5 w-5" />,
    },

    {
        id: 11,
        Name: "Session Request",
        Icon: <AiIcon.AiOutlineNotification className="h-5 w-5" />,
    },

    {
        id: 4,
        Name: "Logout",
        Icon: <TbIcon.TbLogout className="h-6 w-6" />,
    },
]


