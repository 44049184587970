import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MainHeading from '../../Components/MainHeading'
import * as BiIcon from "react-icons/bi"
import { FaPlusCircle } from "react-icons/fa";


import Paragraph from '../../Components/Paragraph'

import { BaseUrl } from '../../Constants/BaseUrl'

import WarningPopUp from '../../Components/WarningPopUp'
import { ToastContainer, toast } from 'react-toastify';
import * as DataAction from "../../Store/DataAction"








const PastPapers = (props) => {
    const dispatch = useDispatch()




    const [loading, setLoading] = useState(false)




    let { paperId, courseName } = useParams();


    const ShowErrorError = (error) => {
        return toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }


    const ShowSuccessMsg = (message) => {
        return toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }


    const [McqsTable, setMcqsTable] = useState([
        {
            Id: Math.random(),
            List: [
                { id: Math.random(), option: "A", isSelect: false },
                { id: Math.random(), option: "B", isSelect: false },
                { id: Math.random(), option: "C", isSelect: false },
                { id: Math.random(), option: "D", isSelect: false }
            ],
        }
    ])

    const AddMcqsHandler = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": paperId,
            "IsMcqs": true,
            "McqsList": McqsTable
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}pastPaper/api/updatePastPaperRecord`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    ShowSuccessMsg("MCQ's added successfully")
                } else {
                    ShowErrorError("Oops! something went wrong")
                }
            })
            .catch(error => console.log('error', error));
    }


    const SelectOptHandler = (i, j) => {
        let List = [...McqsTable]
        for (const key in List) {
            if (List[key].Id === i.Id) {
                let data = List[key].List
                for (let index = 0; index < data.length; index++) {
                    if (data[index].id === j.id) {
                        data[index].isSelect = !data[index].isSelect
                    } else {
                        data[index].isSelect = false
                    }
                }
            }
        }
        setMcqsTable(List)
    }

    return (
        <div className="w-full h-[100vh]  bg-cover  items-center overflow-hidden">
            <div className="w-full h-screen bg-opacityBgColor flex">
                <div className="">
                    <SideBar />
                </div>
                <div className={" h-full w-full bg-bgGray"}>



                    <div className=' w-full bg-bgGray  items-center justify-between px-6'>

                        <div className=''>
                            <MainHeading
                                text={courseName}
                            />


                        </div>

                        <div className='h-[90vh] w-full bg-white rounded-lg p-4' >
                            <div className='h-[40%] w-full bg-gray-300 rounded-lg flex justify-center items-center'>
                                <Paragraph
                                    text="PDF will desplay here"
                                />
                            </div>

                            <div className='h-[60%] w-full'>
                                <div className='h-[10%] w-full bg-fontColor mt-2 rounded-md flex'>
                                    <div className='w-[7%] h-full flex justify-center items-center text-white font-bold'>
                                        <p>#</p>
                                    </div>
                                    <div className='w-[78%] h-full flex justify-center items-center text-white font-bold'>
                                        <p>MCQ's Solvers</p>
                                    </div>
                                    <div className='w-[10%] h-full flex justify-end items-center text-white font-bold cursor-pointer'>
                                        <div
                                            onClick={() => {
                                                let List = [...McqsTable]
                                                List.push({
                                                    Id: Math.random(),
                                                    List: [
                                                        { id: Math.random(), option: "A", isSelect: false },
                                                        { id: Math.random(), option: "B", isSelect: false },
                                                        { id: Math.random(), option: "C", isSelect: false },
                                                        { id: Math.random(), option: "D", isSelect: false }
                                                    ],
                                                })
                                                setMcqsTable(List)

                                            }}
                                        >
                                            <FaPlusCircle />
                                        </div>
                                        <div onClick={AddMcqsHandler}>
                                            <Paragraph
                                                className={"text-yellow ml-3"}
                                                text="PROCEED"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='overflow-scroll	h-[85%]'>
                                    {
                                        McqsTable?.map((i, index) => {
                                            return (
                                                <div className='w-full flex my-2 h-10'>
                                                    <div className='w-[7%] flex justify-center items-center text-fontColor font-bold'>
                                                        <p>{index + 1}</p>
                                                    </div>
                                                    <div className='w-[90%]  h-full flex justify-between items-center'>
                                                        {i.List.map((j) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        SelectOptHandler(i, j)
                                                                    }}
                                                                    className={`h-full w-[24%] ${j.isSelect ? "bg-green-600" : "bg-bgGray"}  rounded-md flex justify-center items-center text-[3xl] cursor-pointer`}>
                                                                    <Paragraph
                                                                        text={j.option}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>


                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


            </div>











            <ToastContainer />

        </div>
    )
}

export default PastPapers