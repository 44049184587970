import React, { useState } from 'react'
import * as BiIcon from "react-icons/gi"
import UserProfile from "../Components/UserProfile"
import SideBarData from "../Components/SideBarData"
import { useSelector } from 'react-redux'
const SideBar = () => {
    const [toggle, setToggle] = useState(false)
    const ProfileData = useSelector((state) => state.auth.userSuccessData)

    return (
        <div className={`${toggle ? ":w-[5.8rem]" : ""} ${toggle ? "sidebar-container-close" : "sidebar-container"} `}>
            <UserProfile toggle={toggle} />
            <div className='mt-4'>
                <SideBarData toggle={toggle} />
            </div>

            <div
                onClick={() => {
                    setToggle(!toggle)
                }}
                className={`absolute top-[1rem] flex justify-center items-center ${toggle ? 'left-16' : 'left-48'}  ml-[10px] w-10 h-10 bg-[#02A7A1] rounded-sm cursor-pointer transition-all duration-3000`}>
                <BiIcon.GiHamburgerMenu className={`${toggle ? "rotate-180" : ""} text-xl transition-all duration-300`} />
            </div>
        </div>
    )
}

export default SideBar