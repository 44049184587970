import logo from './logo.svg';
import './App.css';
import LoginPage from './Pages/Auth/LoginPage';
import { Provider } from "react-redux"
import ReduxThunk from 'redux-thunk';

import MainRoutes from './Routes/MainRoutes';
import AuthReducer from "./Store/AuthReducer"
import DataReducer from './Store/DataReducer';
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from "redux"

function App() {

  const rootReducer = combineReducers({
    auth: AuthReducer,
    data: DataReducer

  })

  const Store = createStore(rootReducer, applyMiddleware(ReduxThunk))

  return (
    <Provider store={Store}>
      <MainRoutes />
    </Provider>
  );
}

export default App;
