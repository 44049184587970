import React, { useEffect, useState } from 'react'
import * as MdIcon from "react-icons/md"
import Paragraph from './Paragraph'
import MyLoader from './MyLoader'
const ClassCommentPopUpReadOnly = (props) => {
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState("")


    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center'>
            <div className=' w-[30%] bg-white p-4 rounded-xl' >
                <div className='flex flex-col justify-center p-2 items-center'>

                    <div className='flex ustify-center items-center'>
                        <h4 className=' text-fontColor font-bold'>Conversation Comments</h4>
                    </div>

                    <div className='text-center  w-full  rounded-lg'>
                        <textarea
                            readonly
                            value={props.value}
                            rows="10"
                            onChange={props.onChange}
                            className="w-full min-h-0 h-full border rounded-xl py-2 px-3  text-black  placeholder-lightgray leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Enter Comments Here."
                        />

                    </div>




                    <div>
                        <h4 onClick={props.onCancel} className='text-black text-sm mt-2 font-semibold cursor-pointer'>Cencel</h4>
                    </div>




                </div>


            </div>
        </div>
    )
}

export default ClassCommentPopUpReadOnly