import React, { useEffect, useState } from 'react'
import * as MdIcon from "react-icons/md"
import Paragraph from './Paragraph'
import MyLoader from './MyLoader'
const CreatePastPaperPopUp = (props) => {




    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center'>
            <div className=' w-[30%] bg-white p-4 rounded-xl' >
                <div className='flex flex-col justify-center p-2 items-center'>

                    <div className='flex ustify-center items-center'>
                        <h4 className=' text-fontColor font-bold'>Past Paper Creation</h4>
                    </div>

                    <div className='w-full  rounded-lg py-2'>
                        <div className=''>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Paper Name</label>
                            <input onChange={props.onChangeName} value={props.Name} type="name" name="name" id="password" placeholder="Board name here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>
                    </div>

                    <div className='w-full  rounded-lg py-2'>
                        <div className=''>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Paper Link</label>
                            <input onChange={props.onChangeLink} value={props.Link} type="name" name="name" id="password" placeholder="Paper link here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>
                    </div>

                    <div className='w-full justify-between items-center flex'>
                        <div className='w-[48%]  rounded-lg py-2'>
                            <div className=''>
                                <label for="email" className="block text-fontColor bold text-sm font-medium ">Year</label>
                                <input onChange={props.onChangeYear} value={props.Year} type="name" name="name" id="password" placeholder="Year here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                        </div>

                        <div className='w-[48%]  rounded-lg py-2'>
                            <div className=''>
                                <label for="email" className="block text-fontColor bold text-sm font-medium ">Sesion</label>
                                <input onChange={props.onChangeSeason} value={props.Season} type="name" name="name" id="password" placeholder="Season name here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>

                        </div>

                    </div>

                    {
                        props.loading ? <div className='w-full flex justify-center items-center'>
                            <MyLoader />
                        </div> : <button
                            onClick={props.onProceed}
                            className="w-full mt-2  bg-[#FFCF01] hover:text-[#FFCF01] hover:bg-[#002E60] text-[#002E60] font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline" type="button">
                            PROCEED
                        </button>
                    }


                    <div onClick={props.onClose}>
                        <h4 onClick={props.onClose} className='text-black text-sm mt-2 font-semibold cursor-pointer'>Cencel</h4>
                    </div>




                </div>


            </div>
        </div>
    )
}

export default CreatePastPaperPopUp