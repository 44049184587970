export const GetALlStudentsConst = {
    GET_ALL_STUDENT: "GET_ALL_STUDENT"
}

export const GetALlStudentsByIdConst = {
    GET_ALL_STUDENT_BY_ID: "GET_ALL_STUDENT_BY_ID"
}

export const SelectQuizStudentActionConst = {
    SELECT_UNSELECT_QUIZ_STUDENT: "SELECT_UNSELECT_QUIZ_STUDENT"
}


export const GetAllMasterBoardsActionConst = {
    GET_ALL_MASTER_BOARD_REQ: "GET_ALL_MASTER_BOARD_REQ",
    GET_ALL_MASTER_BOARD_SUCC: "GET_ALL_MASTER_BOARD_SUCC",
    GET_ALL_MASTER_BOARD_FAIL: "GET_ALL_MASTER_BOARD_FAIL",

    ACTIVE_TILE_TOGGLE: "ACTIVE_TILE_TOGGLE"


}
