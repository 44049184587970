import { BrowserRouter, Routes, Route } from "react-router-dom"
import React from 'react'

import Dashboard from "../Pages/Home/Dashboard"
import LoginPage from "../Pages/Auth/LoginPage"


import ProtectedRoute from "../Components/ProtectedRoute"
import AllStudents from "../Pages/Home/AllStudents"
import AllBoardsPage from "../Pages/Home/AllBoardsPage"
import SideBarLiveSessionRequestPage from "../Pages/Home/SideBarLiveSessionRequestPage"
import PastPapers from "../Pages/Home/PastPapers"
import PastPaperMCQsHandler from "../Pages/Home/PastPaperMCQsHandler"
import CreateMyPaper from "../Pages/Home/CreateMyPaper"
const MainRoutes = () => {
    return (

        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<ProtectedRoute Component={Dashboard} />} />
                <Route path="/allStudents" element={<ProtectedRoute Component={AllStudents} />} />
                <Route path="/AllBoardsPage" element={<ProtectedRoute Component={AllBoardsPage} />} />
                <Route path="/SessionRequestsList" element={<ProtectedRoute Component={SideBarLiveSessionRequestPage} />} />
                <Route path="/PastPapers" element={<ProtectedRoute Component={PastPapers} />} />
                <Route path="/CreateMyPaper" element={<ProtectedRoute Component={CreateMyPaper} />} />
                <Route path="/pastPaperMcqHandler/:paperId/:courseName" element={<ProtectedRoute Component={PastPaperMCQsHandler} />} />

            </Routes>
        </BrowserRouter>
    )
}

export default MainRoutes