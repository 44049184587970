import React, { useEffect, useState } from 'react'
import * as MdIcon from "react-icons/md"
import { BaseUrl } from '../Constants/BaseUrl'
import MyLoader from './MyLoader'
import { ToastContainer, toast } from 'react-toastify';
const CreateUpdateStudentpopUp = (props) => {
    const Infotmation = props.data

    const [loading, setLoading] = useState(false)



    const [formFields, setFormsFields] = useState({
        fullName: "",
        number: "",
        email: "",
        password: "",
    })



    const ShowErrorError = (error) => {
        return toast.error(error, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }


    const ShowSeccessError = (error) => {
        return toast.success(error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }

    useEffect(() => {
        setFormsFields({
            ...formFields,
            fullName: Infotmation?.fullName,
            number: Infotmation?.number,
            password: Infotmation?.password,
            email: Infotmation?.email
        })

    }, [Infotmation])



    const UpdateHandler = () => {
        if (formFields.fullName === "" || formFields.number === "" || formFields.email === "" || formFields.password === "") {
            ShowErrorError("Input Fields Required")
        } else {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "_id": Infotmation._id,
                "fullName": formFields.fullName,
                "email": formFields.email,
                "password": formFields.password,
                "number": formFields.number
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}student/api/updateStudentRecord`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 200) {
                        ShowSeccessError("Student Information Updated Successfully")
                        setLoading(false)
                    } else {
                        ShowErrorError("Oops! Something Went Wrong")
                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }


    }



    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center'>
            <div className=' w-[30%] bg-white p-4'>
                <div className='flex flex-row justify-between p-2'>
                    <div className='flex justify-center items-center'>
                        <h4 className=' text-fontColor font-bold'>Update Students</h4>
                    </div>

                    <div onClick={props.onCancel} className='cursor-pointer text-fontColor text-[30px]'>
                        <MdIcon.MdCancel />
                    </div>

                </div>

                <div className='w-100'>
                    <form class=" " action="#">
                        <div className='flex flex-row   items-center m-2'>
                            <div className='w-2/4'>
                                <label for="text" className="block text-fontColor bold text-sm font-medium ">Full Name</label>
                                <input value={formFields.fullName} onChange={(e) => {
                                    setFormsFields({
                                        ...formFields,
                                        fullName: e.target.value
                                    })
                                }} type="string" name="firstName" class=" bg-gray-50 border border-gray-300 text-[#104871] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-[95%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Full Name" required="" />
                            </div>

                            <div className='w-2/4'>
                                <label for="text" className="block text-fontColor bold text-sm font-medium ">Number</label>
                                <input value={formFields.number} onChange={(e) => {
                                    setFormsFields({
                                        ...formFields,
                                        number: e.target.value
                                    })
                                }} type="string" name="lastName" class="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Number" required="" />
                            </div>
                        </div>

                        <div className='m-2'>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Email</label>
                            <input onChange={(e) => {
                                setFormsFields({
                                    ...formFields,
                                    email: e.target.value
                                })
                            }} value={formFields.email} type="email" name="password" id="password" placeholder="xyz@email.com" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>

                        <div className='m-2'>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Password</label>
                            <input onChange={(e) => {
                                setFormsFields({
                                    ...formFields,
                                    password: e.target.value
                                })
                            }} value={formFields.password} type="email" name="password" id="password" placeholder="Password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>

                        <div className='m-2'>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Country</label>
                            <input disabled value={Infotmation?.countryName} type="email" name="password" id="password" placeholder="Password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>







                    </form>
                    <div>
                        {loading ? <div className='flex justify-center items-center w-full'>
                            <MyLoader />
                        </div> : <div onClick={UpdateHandler} className="h-12 w-full font-bold bg-fontColor mt-4 rounded-lg flex justify-center items-center cursor-pointer" >
                            <p className='text-white'>UPDATE </p>
                        </div>}
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default CreateUpdateStudentpopUp