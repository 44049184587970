import { GetAllMasterBoardsActionConst } from "./DataActionConst"

const initialState = {
    loader: false,
    allMasterBoard: []

}
export default (state = initialState, action) => {
    switch (action.type) {
        case GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_REQ:
            state = {
                ...state,
                loader: true,
            }
            break

        case GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_SUCC:
            state = {
                ...state,
                loader: false,
                allMasterBoard: action.data
            }
            break


        case GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_FAIL:
            state = {
                ...state,
                loader: false,
            }
            break

        case GetAllMasterBoardsActionConst.ACTIVE_TILE_TOGGLE:

            let list = state.allMasterBoard
            for (const key in list) {
                if (list[key]._id === action.boardId) {
                    list[key].active = !list[key].active
                }else{
                    list[key].active = false
                }
            }
            state = {
                ...state,
                allMasterBoard: list
            }
            break

    }
    return state

}