import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as BiIcon from "react-icons/bi"
import { MdOutlineCheckBox } from "react-icons/md";
import { IoMdCheckbox } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import ClassCommentPopUp from '../../Components/ClassCommentPopUp'

import * as PiIcon from "react-icons/pi"

import * as FaIcon from "react-icons/fa"
import { BaseUrl } from '../../Constants/BaseUrl'




const Dashboard = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [liveSesscionRecords, setLiveSesscionRecords] = useState([])
  const [search, setSearch] = useState("")
  const [showCommentPopUp, setShowCommentPopUp] = useState(false)
  const [selectedLive, setSelectedLive] = useState()

  const [comment, setComment] = useState("")
  const [liveLoding, setLiveLoading] = useState(false)


  const CountList = [
    {
      id: 1,
      Name: "All Students",
      Icon: <PiIcon.PiStudentFill />,
      Count: 300,
      ImagePath: require("../../Assets/Student.png")


    },
    {
      id: 1,
      Name: "Quizess",
      Icon: <FaIcon.FaListOl />,
      Count: 200,
      ImagePath: require("../../Assets/Quizzes.png")
    },
    {
      id: 1,
      Name: "Past Papers",
      Icon: <FaIcon.FaNewspaper />,
      Count: 700,

      ImagePath: require("../../Assets/Paper.png")
    },
    {
      id: 1,
      Name: "Boards",
      Icon: <FaIcon.FaNewspaper />,
      Count: 20,

      ImagePath: require("../../Assets/education.png")
    }
  ]

  const Listing = [
    { id: 1, Name: "Session Request", count: 100 },
    { id: 2, Name: "Assistant Admin", count: 20 },
    { id: 2, Name: "Discuss Soon", count: 10 },
    { id: 2, Name: "Discuss Soon 1", count: 40 },
  ]


  const HeadingList = [
    {
      id: 1,
      Name: "Subject",
      Email: "Name",
      Number: "Number",
      Description: "Description",
      State: "Action"
    }
  ]


  const GetAllLiveRecords = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${BaseUrl}liveClass/api/getAllliveClasses`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log()
        if (result.status === "Success") {
          setLiveSesscionRecords(result.data)
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetAllLiveRecords()
  }, [])

  const ShowErrorError = (error) => {
    return toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const ShowSuccessMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }


  const ProceedHandler = () => {
    if (comment === "") {
      ShowErrorError("Comments Cannot Be Empty")
    } else {




      setLiveLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "_id": selectedLive._id,
        "comments": comment
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${BaseUrl}liveClass/api/updateLiveClassRecord`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 200) {
            setLiveLoading(false)
            GetAllLiveRecords()
            setShowCommentPopUp(false)
            ShowSuccessMsg("Request Submitted Successfully")


          }
        })
        .catch(error => console.log('error', error));
    }
  }


  return (
    <div className="w-full  bg-cover  items-center overflow-hidden">
      <div className="w-full h-screen bg-opacityBgColor flex">
        <div className="">
          <SideBar />
        </div>
        <div className={" h-full w-full bg-bgGray"}>
          {/* Search Header */}

          <div className='h-20 w-full bg-white flex items-center'>
            <div className='h-10 w-80 bg-white ml-10 items-center rounded-md border flex justify-between px-2  text-bgGray'>
              <div className='text-1xl'>
                <BiIcon.BiSearchAlt2 />
              </div>

              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Search here'
                className='w-full m-2 text-inpu border-none  outline-none text-md text-black'

              />
            </div>
          </div>

          <div className='px-20 py-4'>
            <h4
              className='text-xl font-bold text-fontColor'
            >DASHBOARD</h4>

            <div className='flex-wrap flex my-1 '>
              {CountList.map((i) => {
                return (
                  <div className='h-[13vh] w-[24.3%] bg-white mr-1.5 rounded-xl flex p-10'>
                    <div className='h-full w-full  flex'>
                      <div className='w-2/4 h-full justify-center flex flex-col'>
                        <p className='text-[0.8rem] text-black'>{i.Name}</p>
                        <h1 className='font-bold text-2xl text-fontColor'>{i.Count}</h1>
                      </div>
                      <div className='w-2/4 h-full text-fontColor font-bold flex justify-center items-center text-5xl'>
                        <img className='h-16' src={i.ImagePath} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>


            <div className='flex-wrap flex mt-2 '>
              {Listing.map((i) => {
                return (
                  <div className='h-[13vh] w-[24.3%] bg-white mr-1.5 rounded-xl flex p-10'>
                    <div className='h-full w-full  flex'>
                      <div className='w-full h-full justify-center flex flex-col items-center'>
                        <p className=' font-bold text-2xl text-[rgb(2,167,161)]'>{i.count}</p>
                        <h1 className='font-bold text-lg text-fontColor'>{i.Name}</h1>
                      </div>

                    </div>
                  </div>
                )
              })}
            </div>




          </div>

          <div className='px-20 '>
            <h4
              className='text-xl font-bold text-fontColor mb-2'
            >Live Session Requests</h4>
          </div>

          <div className='px-20'>

            <div className=' w-full bg-fontColor text-white justify-center rounded py-2'>
              {
                HeadingList.map((i) => {
                  return (
                    <div className='flex-wrap flex justify-between px-2 items-center font-semibold text-[1rem]'>
                      <p className='w-[15%] font-bold'>{(i?.Name)}</p>
                      <p className='w-[15%]'> {i.Email}</p>
                      <p className='w-[10%]'> {i.Number}</p>
                      <p className='w-[40%]'>{i.Description}</p>
                      <p className='w-[10%]'>{i.State}</p>
                    </div>
                  )
                })
              }
            </div>


            <div className='bg-white h-[40vh] rounded p-2 overflow-y-scroll mt-2'>
              {
                liveSesscionRecords.map((i) => {
                  if (i?.subject?.toUpperCase()?.includes(search?.toUpperCase())) {
                    if (i.comments === "") {
                      return (
                        <div className='flex-wrap flex justify-between px-2 items-center border rounded p-1 py-1 mt-1 '>
                          <p className='w-[15%] font-bold'>{(i?.subject)?.toUpperCase()}</p>
                          <p className='w-[15%]'> {i?.studentInfor?.fullName}</p>
                          <p className='w-[10%]'> {i?.studentInfor?.number}</p>
                          <p className='w-[45%]'>{i?.description}</p>
                          <p onClick={() => {
                            setSelectedLive(i)
                            setShowCommentPopUp(true)
                          }} className='w-[8%] text-fontColor text-2xl cursor-pointer flex'>
                            {
                              i.isResponded ? <IoMdCheckbox />
                                : <MdOutlineCheckBox />
                            }

                          </p>
                        </div>
                      )
                    }

                  }

                })
              }



            </div>


          </div>



          {
            showCommentPopUp && <ClassCommentPopUp
              onProceed={ProceedHandler}
              loading={liveLoding}
              onChange={(e) => { setComment(e.target.value) }}
              onCancel={() => setShowCommentPopUp(false)}
            />

          }

          <ToastContainer />





        </div>

      </div>

    </div>
  )
}

export default Dashboard