import React, { useEffect, useState } from 'react'
import * as MdIcon from "react-icons/md"
import Paragraph from './Paragraph'
import MyLoader from './MyLoader'
import Switch from "react-switch";
import { BaseUrl } from '../Constants/BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
const CountryPopUp = (props) => {
    const Data = props.selectedBoard
    const [CountryList, setCountryList] = useState([])
    const [loading, setLoading] = useState(false)
    const [boardName, setBoardName] = useState("")
    const [search, setSearch] = useState("")
    useEffect(() => {
        if (Data) {
            setCountryList(Data?.countryList)
            setBoardName(Data?.boardName)
        }
    }, [Data])

    const ShowSuccessToast = (msg) => {
        return toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }
    const ProceedHandler = () => {
        setLoading(true)
        const Count = Data?.countryList?.filter((i) => i.active)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": Data._id,
            "boardName": boardName,
            "countryList": Data.countryList,
            "activeCountries": Count.length
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${BaseUrl}board/api/updateMainBoard`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    ShowSuccessToast("Record updated successfully")
                    setLoading(false)
                    setSearch("")
                    props.onClose()
                }

            })
            .catch(error => console.log('error', error));
    }

    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center'>
            <div className=' w-[30%] bg-white p-4 rounded-xl' >
                <div className='flex flex-col justify-center p-2 items-center'>
                    <div className='flex ustify-center items-center'>
                        <h4 className=' text-fontColor font-bold'>Country Management</h4>
                    </div>

                    {
                        props.eidtName && <div className='w-full rounded-lg py-2'>
                            <div className=''>
                                <label for="email" className="block text-fontColor bold text-sm font-medium ">Board Name</label>
                                <input onChange={(e) => {
                                    setBoardName(e.target.value)
                                }} value={boardName} type="name" name="name" id="password" placeholder="Board name here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                        </div>

                    }

                    <div className='w-full  rounded-lg '>
                        <div className=''>
                            <label for="email" className="block text-fontColor bold text-sm font-medium ">Search Country</label>
                            <input onChange={(e) => setSearch(e.target.value)} value={search} type="name" name="name" id="password" placeholder="Country name here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>
                    </div>
                    <div className='h-[50vh] w-full bg-gray-100 rounded-md overflow-x-scroll py-2'>
                        {
                            CountryList?.map((i) => {
                                if (i.name.toUpperCase().includes(search.toUpperCase())) {
                                    return (
                                        <div className='w-full  mb-2 h-10 px-2 flex justify-between font-bold items-center'>
                                            <p>{`${(i?.name)?.toUpperCase()}`}</p>
                                            <div className=''>
                                                <Switch onChange={() => {
                                                    let List = [...Data.countryList]
                                                    List.map((j) => {
                                                        if (j.numeric === i.numeric) {
                                                            j.active = !j.active
                                                        }
                                                        return j

                                                    })
                                                    setCountryList(List)

                                                }} checked={i.active} />
                                            </div>

                                        </div>
                                    )
                                }

                            })
                        }
                    </div>

                    {
                        loading ? <div className='w-full flex justify-center items-center mt-2'>
                            <MyLoader />
                        </div> : <button
                            onClick={ProceedHandler}
                            className="w-full mt-2  bg-[#FFCF01] hover:text-[#FFCF01] hover:bg-[#002E60] text-[#002E60] font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline" type="button">
                            PROCEED
                        </button>
                    }


                    {/* <div onClick={props.onClose}>
                        <h4 onClick={props.onCancel} className='text-black text-sm mt-2 font-semibold cursor-pointer'>Cencel</h4>
                    </div> */}
                </div>


            </div>
            <ToastContainer />
        </div>
    )
}

export default CountryPopUp