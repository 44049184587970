import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MainHeading from '../../Components/MainHeading'
import * as BiIcon from "react-icons/bi"

import Paragraph from '../../Components/Paragraph'
import { BaseUrl } from '../../Constants/BaseUrl'
import { MdDelete, MdEditNote } from "react-icons/md";
import WarningPopUp from '../../Components/WarningPopUp'
import { ToastContainer, toast } from 'react-toastify';
import * as DataAction from "../../Store/DataAction"
import { FaCaretDown, FaCaretUp, FaFlag, FaEdit } from "react-icons/fa";
import { IoMdAddCircle, IoIosAddCircleOutline } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { GoFilter } from "react-icons/go";

import CreateBordPopup from '../../Components/CreateBordPopup'
import CreateSubBordPopup from "../../Components/CreateSubBordPopup"
import CreateSubjectBordPopup from '../../Components/CreateSubjectBordPopup'
import CountryPopUp from '../../Components/CountryPopUp'







const AllBoardsPage = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [search, setSearch] = useState("")

    const [loading, setLoading] = useState(false)

    const [boardPopUp, setBoardPopUp] = useState(false)
    const [boardName, setBoardName] = useState("")

    const [SubBoardPopUp, setSubBoardPopUp] = useState(false)
    const [SubBoardName, setSubBoardName] = useState("")

    const [SubjectPopUp, setSubjectPopUp] = useState(false)
    const [SubjectName, setSubjectName] = useState("")

    const [countryPopUp, setCountryPopUp] = useState(false)

    const { allMasterBoard, loader } = useSelector((state) => state.data)

    const [warningPopUp, setWarningPopUp] = useState(false)

    const [selectedSubject, setSelectedSubject] = useState()



    const ShowErrorError = (error) => {
        return toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }


    const ShowSuccessMsg = (error) => {
        return toast.success(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }




    useEffect(() => {
        dispatch(DataAction.GetAllMasterBoard())
    }, [])


    const [allSubject, setAllSubject] = useState([])

    const [selectedBoard, setSelectedBoard] = useState()
    const [selectedId, setSelectedId] = useState()

    const GetSubjectById = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "subBoardId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}subject/api/getAllSubjectById`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result, "ALL SUBJECT=====")
                if (result.status === "success") {
                    setAllSubject(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }



    // useEffect(() => {
    //     if (selectedBoard) {
    //         GetSubjectById()
    //     }
    // }, [selectedBoard])


    const BoardCreation = () => {
        if (boardName === "") {
            ShowErrorError("Enter board name first")
        } else {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "boardName": boardName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}board/api/createBoard`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Success") {
                        dispatch(DataAction.GetAllMasterBoard())
                        GetSubjectById()
                        ShowSuccessMsg(result.message)
                        setBoardName("")
                        setLoading(false)
                        setBoardPopUp(false)
                    } else {
                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const SubBoardCreation = () => {
        if (SubBoardName === "") {
            ShowErrorError("Enter board name first")
        } else {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "boardId": selectedBoard._id,
                "subBoardName": SubBoardName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}subBoard/api/createSubBoard`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Success") {
                        dispatch(DataAction.GetAllMasterBoard())
                        GetSubjectById()
                        ShowSuccessMsg(result.message)
                        setSubBoardName("")
                        setLoading(false)
                        setSubBoardPopUp(false)
                    } else {
                        setLoading(false)
                        ShowErrorError(result.message)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    const CreateSubject = () => {

        if (SubjectName === "") {
            ShowErrorError("Enter subject name first")
        } else {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "boardId": selectedBoard._id,
                "subBoardId": selectedId,
                "subjectName": SubjectName,
                "filterList": FilterList,
                "paperFilter": PaperFilter
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}subject/api/createSubject`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Success") {
                        setLoading(false)
                        ShowSuccessMsg(result.message)
                        GetSubjectById(selectedId)
                        setSubjectPopUp(false)
                        setSubjectName("")

                    } else {
                        ShowErrorError(result.message)
                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    const [editName, setEditName] = useState(false)

    const DeleteSubjectHandler = (id) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": selectedSubject._id
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}subject/api/deleteSubjectRecord`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    setLoading(false)
                    setWarningPopUp(false)
                    setSelectedSubject()
                    ShowSuccessMsg("Record delete successfully. ")
                    GetSubjectById(selectedId)
                }
            })
            .catch(error => console.log('error', error));
    }


    const [subjectUpdate, setSubJectUpdate] = useState(false)
    const [updatedSubjectName, setUpdatedSubjectName] = useState("")



    const UpdateSubjectRecord = () => {
        if (updatedSubjectName === "") {
            ShowErrorError("Please enter subject name first")
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "_id": selectedSubject._id,
                "subjectName": updatedSubjectName
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}subject/api/deleteSubjectRecord`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 200) {
                        setSubJectUpdate(false)
                        setUpdatedSubjectName("")
                        setSelectedSubject()
                        GetSubjectById(selectedId)

                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    const [FilterList, setFilterList] = useState([
        {
            id: Math.random(),
            name: "All"
        }
    ])


    const [PaperFilter, setPaperFilter] = useState([
        {
            id: Math.random(),
            name: "All",
            value: 0
        }
    ])

    const [Filtername, setFilterName] = useState("")
    const AddFilterItemHandler = () => {
        if (Filtername === "") {
            ShowErrorError("Please enter filter name first")
        } else {
            let list = [...FilterList]
            const Item = {
                id: Math.random(),
                name: Filtername
            }
            list.push(Item)
            setFilterList(list)
            setFilterName("")
        }



    }

    const AddPaperFilterHandler = () => {
        var Name = Number(PaperFilter.length - 1) + 1


        let list = [...PaperFilter]
        const Item = {
            id: Math.random(),
            name: `Paper ${Name}`,
            value: Name
        }
        list.push(Item)
        setPaperFilter(list)




    }



    return (
        <div className="w-full h-[100vh]  bg-cover  items-center overflow-hidden">
            <div className="w-full h-screen bg-opacityBgColor flex">
                <div className="">
                    <SideBar />
                </div>
                <div className={" h-full w-full bg-bgGray "}>
                    {/* Search Header */}

                    <div className='h-20 w-full bg-white flex items-center justify-between'>
                        <div className='h-10 w-80 bg-white ml-10 items-center rounded-md border flex justify-between px-2  text-bgGray'>
                            <div className='text-1xl'>
                                <BiIcon.BiSearchAlt2 />
                            </div>

                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='Search by name'
                                className='w-full m-2 text-inpu border-none  outline-none text-md text-black'

                            />
                        </div>

                        <div onClick={() => {
                            setBoardPopUp(true)
                        }} className='h-10 flex justify-center items-center pr-6 font-bold text-2xl cursor-pointer'>
                            <IoIosAddCircleOutline />
                            <p className='text-sm font-normal text-fontColor ml-2' >Create Board</p>
                        </div>
                    </div>

                    <div className='px-10 py-5 overflow-auto pb-4'>
                        <div className='flex justify-between mb-2 items-center'>
                            <MainHeading
                                text="ALL BOARDS"
                            />
                        </div>
                        <div className='w-full  max-h-[77vh] verflow-scroll'>
                            {
                                allMasterBoard.map((i) => {
                                    if (i?.boardName?.toUpperCase().includes(search.toUpperCase())) {
                                        const FlatCount = i?.countryList?.filter((item) => item.active)
                                        return (
                                            <div className='w-full  p-3 bg-white mb-2 rounded-lg overflow-auto'>
                                                <div className='h-12 w-full bg-fontColor mb-2 rounded-lg flex'>
                                                    <div onClick={() => {
                                                        setAllSubject([])
                                                        dispatch(DataAction.ActiveBoardTileAction(i._id))
                                                        setSelectedBoard(i)
                                                        setSelectedId(i.subBoardList[0]?._id)
                                                        GetSubjectById(i.subBoardList[0]?._id)

                                                    }} className='h-full w-[5%] flex justify-center items-center text-yellow text-2xl cursor-pointer'>
                                                        {
                                                            i.active ? <FaCaretUp /> : <FaCaretDown />
                                                        }

                                                    </div>
                                                    <div className='h-full w-[85%] flex text-white items-center font-bold text-lg'>
                                                        <h4>
                                                            {i?.boardName?.toUpperCase()}
                                                        </h4>
                                                    </div>
                                                    <div className='h-full w-[10%] flex justify-between items-center px-2'>
                                                        <div
                                                            onClick={() => {
                                                                setEditName(false)
                                                                setSelectedBoard(i)
                                                                setSelectedId(i.subBoardList[0]?._id)
                                                                setCountryPopUp(true)
                                                            }}
                                                            className='text-white position relative cursor-pointer'>
                                                            <FaFlag />
                                                            <div className=''>
                                                                <p className='position absolute bottom-1.5 text-red-400 text-sm font-bold left-4 right-0'>{FlatCount?.length}</p>
                                                            </div>

                                                        </div>
                                                        {
                                                            i.active && <div onClick={() => {
                                                                setSubBoardPopUp(true)
                                                            }} className='text-white cursor-pointer'>
                                                                <IoMdAddCircle />
                                                            </div>
                                                        }

                                                        <div
                                                            onClick={() => {
                                                                setEditName(true)
                                                                setSelectedBoard(i)
                                                                setSelectedId(i.subBoardList[0]?._id)
                                                                setCountryPopUp(true)

                                                            }}
                                                            className='text-yellow cursor-pointer'>
                                                            <FaEdit />
                                                        </div>



                                                    </div>



                                                </div>
                                                {
                                                    i.active && <div className='h-full w-full bg-white mb-2'>
                                                        <div className='w-full flex-wrap flex'>
                                                            <div class="overflow-x-auto">
                                                                <div class="flex flex-nowrap ">
                                                                    <div class="grid grid-cols-8 ">
                                                                        {i.subBoardList.map((item) => {
                                                                            const Name = item.subBoardName
                                                                            const List = Name.slice(" ")

                                                                            return (
                                                                                <div onClick={() => {
                                                                                    GetSubjectById(item?._id)
                                                                                    setSelectedId(item?._id)
                                                                                }} className={`${selectedId === item?._id ? "bg-fontColor" : ""} ${selectedId === item._id ? "text-white" : "text-fontColor"} ${List?.length >= 12 ? "text-[12px]" : ""} h-12 text-center w-auto rounded-xl flex justify-center items-center border-2	text-fontColor px-2 m-2 font-bold hover:bg-yellow`}>
                                                                                    <p onDoubleClick={() => {
                                                                                        alert("hit")
                                                                                    }}>{item.subBoardName}</p>
                                                                                </div>
                                                                            )
                                                                        })}


                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>


                                                        <div className='h-10 w-full bg-[#bbbbbb] rounded-lg flex justify-between px-4'>
                                                            <div className='h-full  flex justify-center items-center text-white '>
                                                                <div className='h-full w-[85%] flex text-black items-center font-semibold '>
                                                                    <h4>
                                                                        Courses
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            {
                                                                selectedId && <div onClick={() => {
                                                                    setSubjectPopUp(true)
                                                                    setFilterList([
                                                                        {
                                                                            id: Math.random(),
                                                                            name: "All"
                                                                        }
                                                                    ])
                                                                }} className='h-full flex justify-center items-center text-black  cursor-pointer text-xl font-bold'>
                                                                    <IoMdAdd />
                                                                </div>
                                                            }

                                                        </div>
                                                        <div className='mt-2'>
                                                            {
                                                                allSubject.map((item) => {
                                                                    return (
                                                                        <div className='h-8 w-full  mb-2 flex justify-between items-center border-b border-gray-200 px-4'>
                                                                            <div className='flex justify-center items-center'>
                                                                                {/* <IoIosCheckboxOutline /> */}
                                                                                {
                                                                                    item._id === selectedSubject?._id && subjectUpdate ? <div className='flex'>
                                                                                        <input
                                                                                            value={updatedSubjectName}
                                                                                            onChange={(e) => setUpdatedSubjectName(e.target.value)}
                                                                                            placeholder='Enter subject name here'
                                                                                            className='w-full m-2 text-inpu border-none outline-none text-md text-black'

                                                                                        />
                                                                                        <button onClick={UpdateSubjectRecord} className='p-1 text-green-800 text-sm font-bold'>UPDATE</button>
                                                                                    </div> : <p className='ml-3'>{item.subjectName}</p>
                                                                                }



                                                                            </div>

                                                                            <div className='flex justify-center items-center cursor-pointer '>
                                                                                <div
                                                                                    className='text-fontColor position relative cursor-pointer mr-6'>
                                                                                    <GoFilter />
                                                                                    <div className=''>
                                                                                        <p className='position absolute bottom-1.5 text-red-400 text-sm font-bold left-4 right-0'>{item?.filterList?.length}</p>
                                                                                    </div>

                                                                                </div>
                                                                                <MdOutlineEdit
                                                                                    onClick={() => {
                                                                                        setSubJectUpdate(true)
                                                                                        setSelectedSubject(item)
                                                                                        setUpdatedSubjectName(item.subjectName)
                                                                                    }}
                                                                                    className='mr-2 text-lg' />
                                                                                <MdDelete onClick={() => {
                                                                                    setSelectedSubject(item)
                                                                                    setWarningPopUp(true)
                                                                                }}
                                                                                    className='mr-2 text-lg text-red-500' />



                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>




                    </div>
                </div>


            </div>
            {
                boardPopUp && <CreateBordPopup
                    value={boardName}
                    loading={loading}
                    onProceed={BoardCreation}
                    onChange={(e) => {
                        setBoardName(e.target.value)
                    }}

                    onClose={() => {
                        setLoading(false)
                        setBoardPopUp(false)
                    }}

                />

            }



            {
                SubBoardPopUp && <CreateSubBordPopup
                    value={SubBoardName}
                    loading={loading}
                    onProceed={SubBoardCreation}
                    onChange={(e) => {
                        setSubBoardName(e.target.value)
                    }}

                    onClose={() => {
                        setLoading(false)
                        setSubBoardPopUp(false)
                    }}

                />

            }



            {
                SubjectPopUp && <CreateSubjectBordPopup
                    value={SubjectName}
                    loading={loading}
                    onProceed={CreateSubject}
                    onChange={(e) => {
                        setSubjectName(e.target.value)
                    }}

                    onClose={() => {
                        setLoading(false)
                        setSubjectPopUp(false)
                    }}

                >
                    <div className=' w-full justify-center items-center'>

                        <div className='flex w-full justify-between items-center'>
                            <div className=''>
                                <label for="email" className="block text-fontColor bold text-sm font-medium ">Filter Name</label>
                                <input onChange={(e) => {
                                    setFilterName(e.target.value)
                                }} value={Filtername} type="name" name="name" id="password" placeholder="Filter name here" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                            <button onClick={AddFilterItemHandler} class="bg-fontColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                                ADD
                            </button>
                        </div>

                        {
                            FilterList.map((i, index) => {
                                return (
                                    <div className={`flex justify-between items-center h-6 w-full mt-1 rounded-sm p-2 ${index % 2 == 0 ? "bg-slate-200" : null}`}>
                                        <p>{`◉ ${i.name}`}</p>
                                        <div>
                                            <MdDelete onClick={() => {
                                                if (i.name === "All") {
                                                    ShowErrorError("All filter cannot be deleted")
                                                } else {
                                                    let List = FilterList.filter((j) => j.name !== i.name)
                                                    setFilterList(List)
                                                }


                                            }} className='mr-2 text-lg text-red-500' />

                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className='mt-8'>
                            <div className='flex w-full justify-between items-center'>
                                <div className=''>
                                    <label for="email" className="block text-fontColor bold text-sm font-medium ">Paper Filters</label>

                                </div>
                                <button onClick={AddPaperFilterHandler} class="bg-fontColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ">
                                    ADD
                                </button>
                            </div>

                            {
                                PaperFilter.map((i, index) => {
                                    return (
                                        <div className={`flex justify-between items-center h-6 w-full mt-1 rounded-sm p-2 ${index % 2 == 0 ? "bg-slate-200" : null}`}>
                                            <p>{`◉ ${i.name}`}</p>
                                            <div>
                                                <MdDelete onClick={() => {
                                                    if (i.name === "All") {
                                                        ShowErrorError("All filter cannot be deleted")
                                                    } else {
                                                        let List = PaperFilter.filter((j) => j.name !== i.name)
                                                        setPaperFilter(List)
                                                    }


                                                }} className='mr-2 text-lg text-red-500' />

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                </CreateSubjectBordPopup>

            }

            {
                countryPopUp && <CountryPopUp
                    eidtName={editName}
                    selectedBoard={selectedBoard}
                    onCancel={() => setCountryPopUp(false)}
                    onClose={() => {
                        setCountryPopUp(false)
                        dispatch(DataAction.GetAllMasterBoard())

                    }}
                />
            }


            {
                warningPopUp && <WarningPopUp
                    loading={loading}
                    Name={selectedSubject?.subjectName}
                    onNoClick={() => setWarningPopUp(false)}
                    onYesClick={DeleteSubjectHandler}

                />
            }
            {/* {updateStudentPopUp && <CreateUpdateStudentpopUp
                data={selectedStudent}
                onCancel={() => {
                    // GetAllStudents()
                    setUpdateStudentPopUp(false)
                }}

            />} */}



            <ToastContainer />
        </div>
    )
}

export default AllBoardsPage