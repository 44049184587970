import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MainHeading from '../../Components/MainHeading'
import * as BiIcon from "react-icons/bi"

import { BaseUrl } from '../../Constants/BaseUrl'

import WarningPopUp from '../../Components/WarningPopUp'
import CreateUpdateStudentpopUp from '../../Components/CreateUpdateStudentpopUp'

import { FaCommentAlt } from "react-icons/fa";
import ClassCommentPopUpReadOnly from '../../Components/ClassCommentPopUpReadOnly'



const HeadingList = [
    {
        id: 1,
        Subject: "Subject",
        StudentName: "Student Name",
        Number: "Number",
        Description: "Description",
        Comment: "Comment",

    }
]

const SideBarLiveSessionRequestPage = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [Students, setStudents] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)

    const [selectedRequest, setSelectedRequest] = useState()
    const [CommentPopUp, setCommentPopUp] = useState(false)


    const [AllSessionRequest, setAllSessionRequest] = useState([])

    const GetAllSessionRequest = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}liveClass/api/getAllliveClasses`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {
                    const Data = result.data.reverse()
                    setAllSessionRequest(Data)

                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetAllSessionRequest()
    }, [])




    return (
        <div className="w-full h-[100vh]  bg-cover  items-center overflow-hidden">
            <div className="w-full h-screen bg-opacityBgColor flex">
                <div className="">
                    <SideBar />
                </div>
                <div className={" h-full w-full bg-bgGray"}>
                    {/* Search Header */}

                    <div className='h-20 w-full bg-white flex items-center'>
                        <div className='h-10 w-80 bg-white ml-10 items-center rounded-md border flex justify-between px-2  text-bgGray'>
                            <div className='text-1xl'>
                                <BiIcon.BiSearchAlt2 />
                            </div>



                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='Search by name'
                                className='w-full m-2 text-inpu border-none  outline-none text-md text-black'

                            />
                        </div>
                    </div>

                    <div className='p-10'>
                        <div className='flex justify-between mb-2 items-center'>
                            <MainHeading
                                text="SESSION REQUEST"
                            />
                            {/* <div>
                                <Paragraph
                                    text="View All"
                                />
                            </div> */}
                        </div>

                        <div className=' w-full bg-fontColor text-white justify-center rounded'>
                            {
                                HeadingList.map((i) => {
                                    return (
                                        <div className='flex-wrap flex justify-between px-2 items-center font-semibold text-[1rem]'>
                                            <p className='w-[15%]'>{i.Subject}</p>
                                            <p className='w-[20%]'>{i.StudentName}</p>
                                            <p className='w-[10%]'> {i.Number}</p>
                                            <p className='w-[45%]'>{i.Description}</p>
                                            <p className='w-[10%]'>{""}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='bg-white h-[75vh] rounded p-2 overflow-y-scroll'>


                            <div className=' w-full text-black justify-center py-2 rounded '>
                                {
                                    AllSessionRequest.map((i) => {
                                        if (i.subject.toUpperCase().includes(search.toUpperCase())) {
                                            if (i.comments !== "") {
                                                return (
                                                    <div className='flex-wrap flex justify-between px-2 items-center border rounded p-1 py-1 mt-1 '>
                                                        <p className='w-[15%] font-bold'>{(i?.subject)?.toUpperCase()}</p>
                                                        <p className='w-[20%]'>
                                                            <p >{i.studentInfor?.fullName}</p>
                                                            <p className='text-[10px] text-fontColor font-bold'>{i?.studentInfor?.countryName}</p>
                                                        </p>
                                                        <p className='w-[10%]'> {i.studentInfor?.number}</p>
                                                        <p className='w-[50%]'> {i?.description}</p>
                                                        <div
                                                            onClick={() => {
                                                                setSelectedRequest(i)
                                                                setCommentPopUp(true)
                                                            }}
                                                            className='w-[5%] flex cursor-pointer'>
                                                            <FaCommentAlt />
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        }

                                    })
                                }
                            </div>

                        </div>


                    </div>
                </div>

            </div>
            {
                CommentPopUp && <ClassCommentPopUpReadOnly
                    value={selectedRequest?.comments}
                    onCancel={() => {
                        setCommentPopUp(false)
                        setSelectedRequest()
                    }}

                />

            }



        </div>
    )
}

export default SideBarLiveSessionRequestPage