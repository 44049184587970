import React, { useEffect, useState } from 'react'
import * as MdIcon from "react-icons/md"
import Paragraph from './Paragraph'
import MyLoader from './MyLoader'
import Switch from "react-switch";
import { BaseUrl } from '../Constants/BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
const SlectFilterPopupAll = (props) => {
    const Data = props.selectedBoard
    const [CountryList, setCountryList] = useState([])
    const [loading, setLoading] = useState(false)
    const [boardName, setBoardName] = useState("")
    const [search, setSearch] = useState("")
    useEffect(() => {
        if (Data) {
            setCountryList(Data?.countryList)
            setBoardName(Data?.boardName)
        }
    }, [Data])

    const ShowSuccessToast = (msg) => {
        return toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }
    const ProceedHandler = () => {
        setLoading(true)
        const Count = Data?.countryList?.filter((i) => i.active)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": Data._id,
            "boardName": boardName,
            "countryList": Data.countryList,
            "activeCountries": Count.length
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${BaseUrl}board/api/updateMainBoard`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    ShowSuccessToast("Record updated successfully")
                    setLoading(false)
                    setSearch("")
                    props.onClose()
                }

            })
            .catch(error => console.log('error', error));
    }

    return (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center'>
            <div className=' w-[30%] bg-white p-4 rounded-xl' >
                <div className='flex flex-col justify-center p-2 items-center'>
                    <div className='flex ustify-center items-center mb-2'>
                        <h4 className=' text-fontColor font-bold'>Board List Management</h4>
                    </div>




                    <div className='h-[50vh] w-full  rounded-md overflow-x-scroll py-2'>
                        {
                            props.children
                        }

                    </div>

                    {
                        loading ? <div className='w-full flex justify-center items-center mt-2'>
                            <MyLoader />
                        </div> : <button
                            onClick={props.onDone}
                            className="w-full mt-2  bg-[#FFCF01] hover:text-[#FFCF01] hover:bg-[#002E60] text-[#002E60] font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline" type="button">
                            DONE
                        </button>
                    }
                </div>


            </div>
            <ToastContainer />
        </div>
    )
}

export default SlectFilterPopupAll