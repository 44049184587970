import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MainHeading from '../../Components/MainHeading'
import * as BiIcon from "react-icons/bi"
import { FaChevronDown } from "react-icons/fa";

import Paragraph from '../../Components/Paragraph'
import { BaseUrl } from '../../Constants/BaseUrl'

import WarningPopUp from '../../Components/WarningPopUp'
import { ToastContainer, toast } from 'react-toastify';
import * as DataAction from "../../Store/DataAction"
import { FaCaretDown, FaCaretUp, FaFlag, FaEdit } from "react-icons/fa";
import { IoMdAddCircle, IoIosAddCircleOutline } from "react-icons/io";
import SlectListPopupAll from '../../Components/SlectListPopupAll'
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosLink } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import Switch from "react-switch";
import CreatePastPaperPopUp from '../../Components/CreatePastPaperPopUp'
import { AiFillFileExcel } from "react-icons/ai";


import SlectFilterPopupAll from "../../Components/SlectFilterPopupAll"






const PastPapers = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [search, setSearch] = useState("")


    const [loading, setLoading] = useState(false)

    const [createPastPaperPopUp, setcreatePastPaperPopUp] = useState(false)
    const [boardName, setBoardName] = useState("")




    const ShowErrorError = (error) => {
        return toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }


    const ShowSuccessMsg = (message) => {
        return toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

    }

    const [formFields, setFormFields] = useState({
        paperName: "",
        paperLInk: "",
        year: "",
        season: ""
    })




    useEffect(() => {
        dispatch(DataAction.GetAllMasterBoard())
    }, [])


    const [allSubject, setAllSubject] = useState([])

    const [selectedBoard, setSelectedBoard] = useState()
    const [selectedId, setSelectedId] = useState()






    const [AllMainBoards, setAllMainBoards] = useState([])
    const [SelectedMaimBoardPopUp, setSelectedMainBoardPopUp] = useState(false)
    const [SelectedMaimBoard, setSelectedMainBoard] = useState()


    const [AllSubBoards, setAllSubBoards] = useState([])
    const [SelectedSubBoardPopUp, setSelectedSubBoardPopUp] = useState(false)
    const [SelectedSubBoard, setSelectedSubBoard] = useState()


    const [AllCourse, setAllCourse] = useState([])
    const [SelectedCoursePopUp, setSelectedCoursePopUp] = useState(false)
    const [SelectedCourse, setSelectedCourse] = useState()



    const [AllFilters, setAllFilter] = useState([])
    const [SelectedFilterPopUp, setSelectedFilterPopUp] = useState(false)
    const [SelectedFilter, setSelectedFilter] = useState()


    const [AllPaperFilter, setAllPaperFilter] = useState([])
    const [selectedPaperFilterPopUp, setSelectedPaperFilterPopUp] = useState(false)
    const [SelectedPaper, setSelectedPaper] = useState()








    const GetAllMainBoard = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}board/api/getAllBoard`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {
                    setAllMainBoards(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }







    const GetAllSubBoard = (boardid) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}subBoard/api/getAllSubBoard`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {
                    let list = result.data.filter((i) => i.boardId === boardid)

                    setAllSubBoards(list)
                }
            })
            .catch(error => console.log('error', error));
    }



    const GetAllCourse = (id) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}subject/api/getAllSubject`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {

                    let list = result.data.filter((i) => i.subBoardId === id)

                    setAllCourse(list)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetAllMainBoard()
        // GetAllSubBoard()
    }, [])



    const [AllPastPapers, setAllPastPapers] = useState([])
    const GetPastPapersByClassId = (id) => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "SubjectId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}pastPaper/api/getPastPaperBySubjectId`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setAllPastPapers(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }




    const CreatePaperHandler = () => {
        if (formFields.paperName === "") {
            ShowErrorError("Paper name is required")
        } else if (formFields.paperLInk === "") {
            ShowErrorError("Paper link is required")
        } else if (formFields.year === "") {
            ShowErrorError("Paper year is required")
        }
        else if (formFields.season === "") {
            ShowErrorError("Paper season is required")
        } else {
            // setLoading(true)
            const APiObject = {
                "PaperName": formFields.paperName,
                "PaperLink": formFields.paperLInk,
                "Year": formFields.year,
                "Season": formFields.year,
                "BoardId": SelectedMaimBoard?._id,
                "SubBoardId": SelectedSubBoard._id,
                "SubjectId": SelectedCourse._id,
                "FilterName": SelectedFilter?.name,
                "PaperFilterName": SelectedPaper.name
            }


            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(APiObject);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BaseUrl}pastPaper/api/createPastPaper`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Success") {
                        setLoading(false)
                        ShowSuccessMsg(result.message)
                        setFormFields({
                            ...formFields,
                            paperName: "",
                            paperLInk: "",
                            year: "",
                            season: ""
                        })

                        GetPastPapersByClassId(SelectedCourse._id)
                    } else {
                        ShowErrorError(result.message)
                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }



    }
    const [selectedExcelFile, setSelectedExcelFile] = useState()
    const [excelWarningPopUp, setExcelWarningPopUp] = useState(false)
    const HandleFileImport = () => {
        // Create an input element dynamically
        const input = document.createElement('input');
        input.type = 'file';

        // Trigger click on the input element
        input.click();

        // Handle file selection
        input.addEventListener('change', (e) => {
            const selectedFile = e.target.files[0];
            setExcelWarningPopUp(true)
            // Perform operations with the selected file, for example:
            console.log('Selected File:', selectedFile);
            setSelectedExcelFile(selectedFile)
            // You can add your logic to process the file here
        });
    };


    const uploadFileToServer = async () => {



        const formdata = new FormData();
        formdata.append("file", selectedExcelFile);
        formdata.append("BoardId", SelectedMaimBoard?._id);
        formdata.append("SubBoardId", SelectedSubBoard._id);
        formdata.append("SubjectId", SelectedCourse._id);
        formdata.append("FilterName", SelectedFilter?.name);
        formdata.append("PaperFilterName", SelectedPaper?.name);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow"
        };

        fetch(`${BaseUrl}pastPaper/api/pastPaper/uploadLacExcel`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success") {
                    setSelectedExcelFile()
                    setExcelWarningPopUp(false)
                    GetPastPapersByClassId(SelectedCourse._id)

                    ShowSuccessMsg("Record Uploaded Successfully")
                } else {
                    ShowErrorError("Something Went Wrong")
                  
                }
            })
            .catch((error) => console.error(error));

        return

        try {
            const formData = new FormData();
            formData.append('file', selectedExcelFile);
            formData.append("BoardId", SelectedMaimBoard?._id);
            formData.append("SubBoardId", SelectedSubBoard._id);
            formData.append("SubjectId", SelectedCourse._id);
            formData.append("FilterName", SelectedFilter?.name);
            formData.append("PaperFilterName", SelectedPaper?.name);
            console.log(formData, "===")
            return



            const response = await fetch(`${BaseUrl}api/pastPaper/uploadLacExcel`, {
                method: 'POST',
                body: formData,
            });


            console.log(response.json(), "UAuauuau")
            if (response.ok) {
                const data = await response.json();
                console.log('File uploaded successfully:', data);
                setSelectedExcelFile()
                setExcelWarningPopUp(false)
                GetPastPapersByClassId(SelectedCourse._id)
                alert("Record Uploaded Successfully")
                // You can handle the response data here
            } else {
                throw new Error('File upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            // Handle error, show message to the user, etc.
        }
    };
    return (
        <div className="w-full h-[100vh]  bg-cover  items-center overflow-hidden">
            <div className="w-full h-screen bg-opacityBgColor flex">
                <div className="">
                    <SideBar />
                </div>
                <div className={" h-full w-full bg-bgGray "}>
                    {/* Search Header */}


                    <div className='h-20 w-full bg-white flex items-center justify-between'>
                        <div className='h-10 w-80 bg-white ml-10 items-center rounded-md border flex justify-between px-2  text-bgGray'>
                            <div className='text-1xl'>
                                <BiIcon.BiSearchAlt2 />
                            </div>

                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='Search by name'
                                className='w-full m-2 text-inpu border-none  outline-none text-md text-black'

                            />
                        </div>

                        {
                            SelectedCourse &&

                            <div className='flex'>
                                <div onClick={HandleFileImport} className='h-10 flex justify-center items-center pr-6 font-bold text-2xl cursor-pointer text-green-700'>
                                    <AiFillFileExcel />

                                    <p className='text-sm font-normal text-fontColor ml-2' >Upload</p>
                                </div>

                                <div onClick={() => {
                                    setcreatePastPaperPopUp(true)

                                }} className='h-10 flex justify-center items-center pr-6 font-bold text-2xl cursor-pointer'>
                                    <IoIosAddCircleOutline />
                                    <p className='text-sm font-normal text-fontColor ml-2' >Create Paper</p>
                                </div>
                            </div>

                        }

                    </div>

                    <div className='px-10 py-5 overflow-auto pb-4'>
                        <div className='flex justify-between mb-2 items-center'>
                            <MainHeading
                                text="ALL PAST PAPERS"
                            />
                        </div>
                        <div className='w-full min-h-[77vh]  max-h-[77vh]  bg-white rounded-lg p-2 overflow-hidden '>
                            <div className=' w-ful justify-between items-center flex'>

                                <div className='h-8 w-[18%] rounded-lg justify-between items-center '>
                                    <Paragraph
                                        className={"text-black mb-1"}
                                        text="BOARD"
                                    />
                                    <div onClick={() => {
                                        setSelectedMainBoardPopUp(true)
                                        setSelectedSubBoard()
                                        setSelectedCourse()
                                        setAllPastPapers([])
                                        setSelectedFilter()
                                    }} className='h-full w-full  bg-fontColor rounded-lg cursor-pointer flex items-center px-2 justify-between items-center'>
                                        <p className={`text-white text-sm ${SelectedMaimBoard ? null : "opacity-50"} `}>
                                            {
                                                SelectedMaimBoard ? SelectedMaimBoard.boardName : "Select board here"
                                            }

                                        </p>
                                        <FaChevronDown className='text-white' />
                                    </div>
                                </div>


                                <div className='h-8 w-[18%] rounded-lg justify-between items-center'>
                                    {
                                        SelectedMaimBoard && <div className=' h-full w-full rounded-lg justify-between items-center'>
                                            <Paragraph
                                                className={"text-black mb-1"}
                                                text="SUB BOARD"
                                            />
                                            <div onClick={() => {
                                                setSelectedSubBoardPopUp(true)
                                                setSelectedCourse()
                                            }} className='h-full w-full  bg-fontColor rounded-lg cursor-pointer flex  px-2 justify-between items-center'>
                                                <p className={`text-white text-sm ${SelectedMaimBoard ? null : "opacity-50"} `}>
                                                    {
                                                        SelectedSubBoard ? SelectedSubBoard.subBoardName : "Select sub board here"
                                                    }

                                                </p>
                                                <FaChevronDown className='text-white' />
                                            </div>
                                        </div>
                                    }



                                </div >



                                <div className='h-8 w-[18%] rounded-lg justify-between items-center '>
                                    {
                                        SelectedSubBoard &&
                                        <div className='h-full w-full rounded-lg justify-between items-center '>
                                            <Paragraph
                                                className={"text-black mb-1"}
                                                text="COURCES"
                                            />
                                            <div onClick={() => {
                                                setSelectedCoursePopUp(true)
                                            }} className='h-full w-full  bg-fontColor rounded-lg cursor-pointer flex  px-2 justify-between items-center'>
                                                <p className={`text-white text-sm ${SelectedMaimBoard ? null : "opacity-50"} `}>
                                                    {
                                                        SelectedCourse ? SelectedCourse?.subjectName : "Select course here"
                                                    }

                                                </p>
                                                <FaChevronDown className='text-white' />
                                            </div>
                                        </div>
                                    }
                                </div>


                                <div className='h-8 w-[18%] rounded-lg justify-between items-center '>
                                    {
                                        SelectedFilter &&
                                        <div className='h-full w-full rounded-lg justify-between items-center '>
                                            <Paragraph
                                                className={"text-black mb-1"}
                                                text="FILTERS"
                                            />
                                            <div onClick={() => {
                                                setSelectedFilterPopUp(true)
                                            }} className='h-full w-full  bg-fontColor rounded-lg cursor-pointer flex  px-2 justify-between items-center'>
                                                <p className={`text-white text-sm ${SelectedMaimBoard ? null : "opacity-50"} `}>
                                                    {
                                                        SelectedFilter ? SelectedFilter?.name : "Select filter here"
                                                    }

                                                </p>
                                                <FaChevronDown className='text-white' />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='h-8 w-[18%] rounded-lg justify-between items-center '>
                                    {
                                        SelectedPaper &&
                                        <div className='h-full w-full rounded-lg justify-between items-center '>
                                            <Paragraph
                                                className={"text-black mb-1"}
                                                text="PAPER FILTER"
                                            />
                                            <div onClick={() => {
                                                setSelectedPaperFilterPopUp(true)
                                            }} className='h-full w-full  bg-fontColor rounded-lg cursor-pointer flex  px-2 justify-between items-center'>
                                                <p className={`text-white text-sm ${SelectedMaimBoard ? null : "opacity-50"} `}>
                                                    {
                                                        SelectedPaper ? SelectedPaper?.name : "Select past here"
                                                    }

                                                </p>
                                                <FaChevronDown className='text-white' />
                                            </div>
                                        </div>
                                    }
                                </div>



                            </div>
                            <div className='w-full  flex  items-center bg-gray-200 mt-8 p-2'>
                                <div className='h-full w-[30%] '>
                                    <Paragraph
                                        className={"text-black "}
                                        text="SUBJECT"
                                    />
                                </div>

                                <div className='h-full w-[20%] '>
                                    <Paragraph
                                        className={"text-black "}
                                        text="YEAR"
                                    />
                                </div>
                                <div className='h-full w-[20%]'>
                                    <Paragraph
                                        className={"text-black "}
                                        text="LINK"
                                    />
                                </div>

                                <div className='h-full w-[10%]'>
                                    <Paragraph
                                        className={"text-black "}
                                        text=""
                                    />
                                </div>

                                <div className='h-full w-[20%] '>
                                    <Paragraph
                                        className={"text-black "}
                                        text="MCQ's"
                                    />
                                </div>
                            </div>

                            <div className='h-[65vh] w-full  pb-6 verflow-scroll overflow-y-scroll'>
                                {AllPastPapers.map((i) => {
                                    return (
                                        <div className='w-full  flex  items-center  p-1 border-b border-gray-200'>
                                            <div className='h-full w-[30%] '>
                                                <Paragraph
                                                    className={"font-bold text-[1rem]"}
                                                    text={i?.PaperName}
                                                />
                                            </div>

                                            <div className='h-full w-[20%] '>
                                                <Paragraph
                                                    className={"font-medium text-[1rem]"}
                                                    text={i?.Year}
                                                />
                                            </div>
                                            <div className='h-full w-[20%]'>
                                                <IoIosLink />
                                            </div>

                                            <div className='h-full w-[10%] flex justify-between px-6'>
                                                <div className='cursor-pointer'>
                                                    <MdEdit className='ml-6' />
                                                </div>
                                                <div className='cursor-pointer'>
                                                    <MdDelete />
                                                </div>
                                            </div>

                                            <div className='h-full w-[20%] '>
                                                <div className=''>
                                                    <Switch

                                                        onChange={() => {
                                                            navigate(`/pastPaperMcqHandler/${i._id}/${SelectedCourse?.subjectName}`)


                                                        }} checked={i.IsMcqs} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>




                    </div>
                </div>


            </div>





            {
                SelectedMaimBoardPopUp && < SlectListPopupAll
                    onDone={() => { setSelectedMainBoardPopUp(false) }}
                >
                    {
                        AllMainBoards?.map((i, index) => {
                            return (
                                <div onClick={() => {
                                    setSelectedMainBoard(i)
                                    GetAllSubBoard(i?._id)
                                }} className={`${index % 2 === 0 ? "bg-gray-100" : null} w-full  mb-2 h-10  px-2 flex justify-between font-bold items-center rounded-lg`}>
                                    <p>{`${(i?.boardName)?.toUpperCase()}`}</p>
                                    <div className='text-xl text-fontColor'>
                                        {
                                            i?._id === SelectedMaimBoard?._id ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                </SlectListPopupAll>
            }


            {
                SelectedSubBoardPopUp && < SlectListPopupAll
                    onDone={() => { setSelectedSubBoardPopUp(false) }}
                >
                    {
                        AllSubBoards?.map((i, index) => {
                            return (
                                <div onClick={() => {
                                    setSelectedSubBoard(i)
                                    GetAllCourse(i._id)
                                }} className={`${index % 2 === 0 ? "bg-gray-100" : null} w-full  mb-2 h-10  px-2 flex justify-between font-bold items-center rounded-lg`}>
                                    <p>{`${(i?.subBoardName)?.toUpperCase()}`}</p>
                                    <div className='text-xl text-fontColor'>
                                        {
                                            i?._id === SelectedSubBoard?._id ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                </SlectListPopupAll>
            }


            {
                SelectedCoursePopUp && < SlectListPopupAll
                    onDone={() => { setSelectedCoursePopUp(false) }}
                >
                    {
                        AllCourse?.map((i, index) => {
                            return (
                                <div onClick={() => {
                                    setSelectedCourse(i)
                                    GetPastPapersByClassId(i._id)

                                    setAllFilter(i?.filterList)
                                    setAllPaperFilter(i?.paperFilter)
                                    setSelectedFilter(i?.filterList[0])
                                    setSelectedPaper(i?.paperFilter[0])
                                }} className={`${index % 2 === 0 ? "bg-gray-100" : null} w-full  mb-2 h-10  px-2 flex justify-between font-bold items-center rounded-lg`}>
                                    <p>{`${(i?.subjectName)?.toUpperCase()}`}</p>
                                    <div className='text-xl text-fontColor'>
                                        {
                                            i?._id === SelectedCourse?._id ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                </SlectListPopupAll>
            }


            {
                selectedPaperFilterPopUp && < SlectFilterPopupAll
                    onDone={() => { setSelectedPaperFilterPopUp(false) }}
                >
                    {
                        AllPaperFilter?.map((i, index) => {
                            return (
                                <div onClick={() => {
                                    setSelectedPaper(i)
                                }} className={`${index % 2 === 0 ? "bg-gray-100" : null} w-full  mb-2 h-10  px-2 flex justify-between font-bold items-center rounded-lg`}>
                                    <p>{`${(i?.name)?.toUpperCase()}`}</p>
                                    <div className='text-xl text-fontColor'>
                                        {
                                            i?.id === SelectedPaper?.id ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                </SlectFilterPopupAll>
            }


            {
                SelectedFilterPopUp && < SlectFilterPopupAll
                    onDone={() => { setSelectedFilterPopUp(false) }}
                >
                    {
                        AllFilters?.map((i, index) => {
                            return (
                                <div onClick={() => {
                                    setSelectedFilter(i)
                                }} className={`${index % 2 === 0 ? "bg-gray-100" : null} w-full  mb-2 h-10  px-2 flex justify-between font-bold items-center rounded-lg`}>
                                    <p>{`${(i?.name)?.toUpperCase()}`}</p>
                                    <div className='text-xl text-fontColor'>
                                        {
                                            i?.id === SelectedFilter?.id ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                </SlectFilterPopupAll>
            }


            {
                createPastPaperPopUp && <CreatePastPaperPopUp
                    loading={loading}
                    Name={formFields.paperName}
                    Link={formFields.paperLInk}
                    Year={formFields.year}
                    Season={formFields.season}
                    onChangeName={(e) => setFormFields({
                        ...formFields,
                        paperName: e.target.value
                    })}

                    onChangeLink={(e) => setFormFields({
                        ...formFields,
                        paperLInk: e.target.value
                    })}

                    onChangeYear={(e) => setFormFields({
                        ...formFields,
                        year: e.target.value
                    })}


                    onChangeSeason={(e) => setFormFields({
                        ...formFields,
                        season: e.target.value
                    })}
                    onProceed={CreatePaperHandler}

                    onClose={() => {
                        setcreatePastPaperPopUp(false)
                    }}

                />
            }

            {
                excelWarningPopUp && <WarningPopUp

                    title={"PAST PAPER UPLOAD"}
                    onNoClick={() => setExcelWarningPopUp(false)}
                    onYesClick={uploadFileToServer}
                    message={`Are you sure you want to upload data from ${selectedExcelFile?.name}`}
                />
            }
            <ToastContainer />

        </div>
    )
}

export default PastPapers