import React, { useEffect, useState } from 'react'
import SideBar from "../../Components/SideBar"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MainHeading from '../../Components/MainHeading'
import * as BiIcon from "react-icons/bi"
import Switch from "react-switch";
import Paragraph from '../../Components/Paragraph'
import { BaseUrl } from '../../Constants/BaseUrl'
import { MdDelete, MdEditNote } from "react-icons/md";
import WarningPopUp from '../../Components/WarningPopUp'
import CreateUpdateStudentpopUp from '../../Components/CreateUpdateStudentpopUp'





const HeadingList = [
    {
        id: 1,
        Name: "Full Name",
        Email: "Email",
        Number: "Number",
        Status: "Satus"
    }
]





const AllStudents = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [Students, setStudents] = useState([])
    const [search, setSearch] = useState("")

    const [loading, setLoading] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState()
    const [warningPopUp, setWarningPopUp] = useState(false)
    const [updateStudentPopUp, setUpdateStudentPopUp] = useState(false)


    const GetAllStudents = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}student/api/getAllStudents`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {
                    setStudents(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetAllStudents()
    }, [])

    const DeleteAccountHanlder = () => {
        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "_id": selectedStudent._id
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}student/api/deleteStudentRecord`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    setWarningPopUp(false)
                    setSelectedStudent()
                    GetAllStudents()
                    setLoading(false)
                }
            })
            .catch(error => console.log('error', error));
    }


    const StudentCheckUpdate = (id, state) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "studentId": id,
            "isAllow": !state
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${BaseUrl}student/api/allowStudent`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    GetAllStudents()
                }
            })
            .catch(error => console.log('error', error));
    }



    return (
        <div className="w-full h-[100vh]  bg-cover  items-center overflow-hidden">
            <div className="w-full h-screen bg-opacityBgColor flex">
                <div className="">
                    <SideBar />
                </div>
                <div className={" h-full w-full bg-bgGray"}>
                    {/* Search Header */}

                    <div className='h-20 w-full bg-white flex items-center'>
                        <div className='h-10 w-80 bg-white ml-10 items-center rounded-md border flex justify-between px-2  text-bgGray'>
                            <div className='text-1xl'>
                                <BiIcon.BiSearchAlt2 />
                            </div>



                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='Search by name'
                                className='w-full m-2 text-inpu border-none  outline-none text-md text-black'

                            />
                        </div>
                    </div>

                    <div className='p-10'>
                        <div className='flex justify-between mb-2 items-center'>
                            <MainHeading
                                text="ALL STUDENTS"
                            />
                            {/* <div>
                                <Paragraph
                                    text="View All"
                                />
                            </div> */}
                        </div>

                        <div className=' w-full bg-fontColor text-white justify-center rounded'>
                            {
                                HeadingList.map((i) => {
                                    return (
                                        <div className='flex-wrap flex justify-between px-2 items-center font-semibold text-[1rem]'>
                                            <p className='w-[30%]'>{i.Name}</p>
                                            <p className='w-[30%]'>{i.Email}</p>
                                            <p className='w-[25%]'> {i.Number}</p>
                                            <p className='w-[15%]'>{i.Status}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='bg-white h-[75vh] rounded p-2 overflow-y-scroll'>


                            <div className=' w-full text-black justify-center py-2 rounded '>
                                {
                                    Students.map((i) => {
                                        if (i.fullName.toUpperCase().includes(search.toUpperCase())) {
                                            return (
                                                <div className='flex-wrap flex justify-between px-2 items-center border rounded p-1 py-1 mt-1 '>
                                                    <p className='w-[30%] font-bold'>{(i?.fullName)?.toUpperCase()}</p>
                                                    <p className='w-[30%]'>{i.email}</p>
                                                    <p className='w-[25%]'> {i.number}</p>
                                                    <div className='w-[15%] flex justify-between'>
                                                        <div>
                                                            <Switch onChange={() => {
                                                                StudentCheckUpdate(i._id, i.isAllow)
                                                            }} checked={i.isAllow} />
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                setSelectedStudent(i)
                                                                setUpdateStudentPopUp(true)

                                                            }}
                                                            className='flex  items-center text-fontColor text-[1.2rem] ml-[20%] cursor-pointer'>
                                                            <MdEditNote />
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                setSelectedStudent(i)
                                                                setWarningPopUp(true)
                                                            }}
                                                            className='flex justify-center items-center text-red-500 text-[1.2rem] cursor-pointer'>
                                                            <MdDelete />
                                                        </div>



                                                    </div>
                                                </div>
                                            )
                                        }

                                    })
                                }
                            </div>

                        </div>


                    </div>
                </div>

            </div>
            {
                warningPopUp && <WarningPopUp
                    loading={loading}
                    Name={selectedStudent?.fullName}
                    onNoClick={() => setWarningPopUp(false)}
                    onYesClick={DeleteAccountHanlder}

                />
            }
            {updateStudentPopUp && <CreateUpdateStudentpopUp
                data={selectedStudent}
                onCancel={() => {
                    GetAllStudents()
                    setUpdateStudentPopUp(false)
                }}

            />}


        </div>
    )
}

export default AllStudents