import React, { useState, useEffect } from 'react'
import * as AiIcon from "react-icons/ai"
import MyLoader from '../../Components/MyLoader';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux"
import * as AuthAction from "../../Store/AuthAction"
import { useNavigate } from "react-router-dom"


const LoginPage = () => {

  const Loading = useSelector((state) => state.auth.loader)
  const UserSuccessData = useSelector((state) => state.auth.userSuccessData)
  const navigate = useNavigate()
  console.log(UserSuccessData, "UserSuccessData Page Login")
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    if (UserSuccessData) {
      navigate("/")
    }
  }, [UserSuccessData])


  const ShowErrorError = (error) => {
    return toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  }

  const loginHandler = () => {

    if (email === "") {
      ShowErrorError("Please enter your email first")

    } else if (password === "") {
      ShowErrorError("Please enter your password first")

    } else {

    } dispatch(AuthAction.UserLoginAction(email, password))
  }

  return (
    <div className="flex h-screen bg-gray-100 p-14 justify-center w-full">
      <div className='flex bg-white rounded-3xl overflow-hidden'>
        <div className="w-50	bg-[#002E60] rounded-3xl flex items-center justify-center ps-12 pe-12 "
        >
          <img
            className=""
            src={process.env.PUBLIC_URL + '/logo.png'}
            alt="Logo"
          />
        </div>
        <div className="w-42 flex items-center justify-center  rounded-e-3xl bg-white p-14"
        >

          <form className="w-96">
            <h2 className="text-2xl font-bold mb-6 ">LOGIN</h2>
            <div className="mb-4">
              <label className="block text-[#002E60] text-sm  mb-2" placeholder="username">
                Email Address
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border rounded-xl py-2 px-3 bg-[#002E60] text-white  placeholder-lightgray leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="xyz@gmail.com"
              />
            </div>
            <div className="mb-6">
              <label className="block text-[#002E60] text-sm  mb-2" placeholder="username">
                Password
              </label>
              <div className='bg-[#002E60] w-full flex justify-between rounded-[8px]'>


                <input
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full  rounded-xl py-2 px-3  bg-[#002E60] text-white placeholder-lightgray leading-tight outline-none shadow-outline"
                  id="password"
                  type={show ? "text" : "password"}
                  placeholder="Password"
                />

                <div
                  onClick={() => setShow(!show)}
                  className='flex justify-center items-center mr-2 text-white text-xl cursor-pointer'>
                  {
                    !show ? <AiIcon.AiFillEye />
                      :
                      <AiIcon.AiOutlineEyeInvisible />
                  }

                </div>
              </div>

            </div>
            {
              Loading ? <div className='w-full flex justify-center items-center'>
                <MyLoader />
              </div> : <button
                onClick={loginHandler}
                className="w-full bg-[#FFCF01] hover:text-[#FFCF01] hover:bg-[#002E60] text-[#002E60] font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline" type="button">
                Login
              </button>
            }




          </form>
        </div>
      </div>

      {/* Same as */}
      <ToastContainer />
    </div>
  );
};




export default LoginPage