import { GetALlStudentsConst, GetALlStudentsByIdConst, SelectQuizStudentActionConst, GetAllMasterBoardsActionConst } from "./DataActionConst";
import { BaseUrl } from "../Constants/BaseUrl"
export const GetALlStudents = () => {
    return async dispatch => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}student/api/allStudents`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "Success") {
                    dispatch({
                        type: GetALlStudentsConst.GET_ALL_STUDENT,
                        students: result.data
                    })
                } else {
                    alert(result?.message)
                }
            })
            .catch(error => console.log('error', error));
    };
};


export const GetALlStudentsByIdAction = (list) => {
    return async dispatch => {
        dispatch({
            type: GetALlStudentsByIdConst.GET_ALL_STUDENT_BY_ID,
            students: list
        })
    };
};



export const SelectQuizStudentAction = (id) => {
    console.log(id, "studentIdstudentIdstudentId")
    return async dispatch => {
        dispatch({
            type: SelectQuizStudentActionConst.SELECT_UNSELECT_QUIZ_STUDENT,
            studentId: id
        })
    };
};


export const GetAllMasterBoard = (id) => {
    return async dispatch => {
        dispatch({
            type: GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_REQ,
        })

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${BaseUrl}board/api/getAllMasterBoard`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === "Success") {
                    let list = result.data
                    for (const key in list) {
                        list[key]["active"] = false
                    }
                    dispatch({
                        type: GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_SUCC,
                        data: list
                    })
                } else {
                    dispatch({
                        type: GetAllMasterBoardsActionConst.GET_ALL_MASTER_BOARD_FAIL,

                    })
                }
            })
            .catch(error => console.log('error', error));
    };
};


export const ActiveBoardTileAction = (id) => {

    return async dispatch => {
        dispatch({
            type: GetAllMasterBoardsActionConst.ACTIVE_TILE_TOGGLE,
            boardId: id
        })
    };
};


